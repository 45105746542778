import { template as template_726e8603d1154e16be7e3ca48b2515df } from "@ember/template-compiler";
const SidebarSectionMessage = template_726e8603d1154e16be7e3ca48b2515df(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
