import { template as template_86b4b355f0f249ef902cba8bac5a1bf9 } from "@ember/template-compiler";
const WelcomeHeader = template_86b4b355f0f249ef902cba8bac5a1bf9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
